import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import Header_sec_navbar from './HeaderSecNavbar';
import Service_section from './Service_section';
import {yantra_kavach} from '../WebData';

export default function yantra() {
  return (
    <>
    
    <div className="header_section header_bg">

              <Header_sec_navbar image={applogo} bookingno={booking_no} />

          </div>
          {/* header section end */}

          <div className="heading_text"> Swami Rupeshwaranand Ashram </div>


{/* Service section start */}
          <div className="classes_section layout_padding">
              <div className="container">
                  <div className="classes_border">
                      <div className="row">
                          <div className="col-md-12">
                              <h1 className="classes_taital">Siddha and consecrated by special mantra and havan</h1>
                              <p className="classes_text">Divine Yantra and Divine Shield for the protection of the family <br/> For more information send WhatsApp message on +91 - 7607 233 230 or make a phone call !!! </p>
                          </div>
                      </div>
                      <div className="classes_section_2">
                          <div className="row">
                              {yantra_kavach.map((item) =>(
                                <Service_section key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc} btn_text={item.btn_text} />
                              ))}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/* Service section end */}

<p><br/> &nbsp; <br/></p>

    </>
  )
}
