import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import Header_sec_navbar from './HeaderSecNavbar';

export default function Refund_policy() {
  React.useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
        
  return (
    <>
    <div className="header_section header_bg">

<Header_sec_navbar image={applogo} bookingno={booking_no} />

</div>
{/* header section end */}

<br/><br/><br/><br/><br/>
<center>
<div className="card-body" style={{ textAlign: "left", width: "60%" }}>
    <h4 className="card-title">Cancellation & Refund Policy</h4>
    <h6 className="card-text">Last updated on August 22nd 2024</h6>
    Swami Rupeshwaranand Ashram believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:<br/>
    
<ul>
    <li>Swami Rupeshwaranand Ashram does not accept cancellation requests for our Pujan services. Ashram related  Pujan, Yagya services, Pujan dakshina are non-refundable.</li>
    <li>After service booking by some reasons received dates can be varied and bookings are non-refundable.</li>
    <li>In case of any Refunds approved by the Swami Rupeshwaranand Ashram, it’ll take 3-5 days for the refund to be processed to the end customer.</li>
    <li>Customer Care Service Number : +91-9817777108</li>
    <li>Mail us at : <a href="mailto:swamirupeshwar@gmail.com">swamirupeshwar@gmail.com</a></li>
</ul>
    
</div>
    </center>
    <br/><br/>
    </>
  )
}
