import React from 'react'
import { Button, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

export default function Textonimage(props) {

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);

  return (
    <>

      <Col lg={true} >
        <Card border="white" className="text-white col m-2">
          <Card.Img src={props.image} alt={props.title} />
          <Card.ImgOverlay>
            <Card.Title style={{ textAlign: "center", color: "white" }}>{props.title}</Card.Title>
            <Card.Text style={{ textAlign: "center", color: "white" }}>
              {numberFormat(props.subtitle)}
            </Card.Text>
            <Card.Text style={{ textAlign: "center", color: "white" }}><Link to='/bookingConfirm' state={{id: 1, image: props.image, title: props.title, price: props.subtitle, text: props.text}} > <Button variant="warning">{props.btn_text}</Button> </Link>  </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </Col>
    </>
  )
}
