import React from 'react'
import { Link } from 'react-router-dom';

export default function Classes_section(props) {

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);

  return (
      <div className="col-md-6">
          <div className="classes_img"><img src={props.image} alt={props.title} /></div>
          <h3 className="practising_taital">{props.title +" ( "+ numberFormat(props.subtitle) +" )"}</h3>
          <div className="blog_text"><div dangerouslySetInnerHTML={{__html: props.text}}></div></div>
          <div className="read_btn" style={(props.btn_text === "" ? {display:"none"} : {display:""})}><Link to='/bookingConfirm' state={{id: 1, image: props.image, title: props.title, price: props.subtitle, text: props.text}} > {props.btn_text} </Link></div>
      </div>
  )
}
