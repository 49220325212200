import React from 'react'
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

export default function YagyaSection(props) {
    const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    const wrapStyle = {
        maxHeight: "240px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }

    return (
        <div className="col-md-4">
            <div className="blog_box">
                <div className="blog_img" >

                    <Carousel interval={1000} controls={false}>
                        <Carousel.Item>
                            <img src={props.image1} alt={props.title} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={props.image2} alt={props.title} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={props.image3} alt={props.title} />
                        </Carousel.Item>
                    </Carousel>

                </div>
                <div className="blog_taital_main">
                    <p className="yoga_text" style={{display:"none"}}>{numberFormat(props.subtitle)}</p>
                    <h3 className="practising_taital">{props.title}</h3>
                    <div className="blog_text" style={wrapStyle}><div dangerouslySetInnerHTML={{ __html: props.text }}></div></div>
                    <div className="date_btn" style={(props.btn_text === "" ? { display: "none" } : { display: "" })}> <Link to={`/yagyaDetails/${props.id}`} state={{ id: props.id }} > {props.btn_text} </Link></div>
                </div>
            </div>
        </div>
    )
}
