import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import Header_sec_navbar from './HeaderSecNavbar';

export default function My404error() {
  return (
    <>
          <div className="header_section">

              <Header_sec_navbar image={applogo} bookingno={booking_no} />

          </div>
          {/* header section end */}

          {/* About section start */}
          <br/><br/><br/><br/><br/><br/><center><h1><b style={{color:"red"}}>The page you are looking for does not exist. <br/> Go to Home. </b></h1></center><br/><br/><br/>
          {/* About section end */}
      </>
  )
}
