import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import Header_sec_navbar from './HeaderSecNavbar';
import Service_section from './Service_section';
import { service_data_2, other_service_data } from '../WebData';
import Textonimage from './Textonimage';

export default function Services(props) {
    React.useEffect(() => {
            window.scrollTo(0, 0);
          }, []);
          
  return (
      <>

          <div className="header_section header_bg">

              <Header_sec_navbar image={applogo} bookingno={booking_no} />

          </div>
          {/* header section end */}

          <div className="heading_text"> Swami Rupeshwaranand Ashram </div>


          {/* Service section start */}
          <div className="classes_section layout_padding">
              <div className="container">
                  <div className="classes_border">
                      <div className="row">
                          <div className="col-md-12">
                              <h1 className="classes_taital">Special Pujan Category</h1>
                              <p className="classes_text">Price List of Pitru Pujan </p>
                          </div>
                      </div>
                      <div className="classes_section_2">
                          <div className="row">
                              {service_data_2.map((item) =>(
                                <Service_section key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc} btn_text={item.btn_text} />
                              ))}
                          </div>
                      </div>
                      {/*<div className="classes_section_2">
                          <div className="row">
                            {service_data_2.slice(0,2).map((val) =>(
                                <Service_section image={val.cover} title={val.title} subtitle={val.subtitle} text={val.desc}/>
                            ))}
                          </div>
                      </div>*/}
                  </div>
              </div>
          </div>
          {/* Service section end */}


          {/* Post section start */}
          <div className="blog_section layout_padding">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <h1 className="blog_taital">{props.service_title}</h1>
                      </div>
                  </div>
                  <div className="blog_section_2">
                      <div className="row">
                      {other_service_data.map((item) => (
                          <Textonimage key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc}  btn_text={item.btn_text} />
                        ))}
                      </div>
                  </div>
              </div>
          </div>
          {/* Post section end */}

          <div className="blog_section">
          <div className="container">
          <ol className="list-group " style={{ textAlign:"left"}}>
            <li aria-current="true" className="list-group-item fw-bold" style={{ color:"#000000", fontSize: "18px", fontWeight: "600", backgroundColor:"#e8c51d"}}>How to book services of ashram</li>
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold"><b>Read &amp; Reach us via WhatsApp</b></div>
                    From wide range of services provided by Ashram select the one suitable for you, Never hesitate to
                    call or message us for any query! We are always available!
                </div>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold"><b>Pay Dakshina &amp; Fill the Form</b></div>
                    Pay the Dakshina amount for the selected Service and fill corresponding form
                </div>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold"><b>Get Confirmation</b></div>
                    Send Payment receipt on +91 - 9817777108 we&#39;ll give you confirmation!
                </div>
            </li>
        </ol>
        </div>
        </div>

          {/*<p><br/> &nbsp; <br/></p>*/}
      </>
  )
}
