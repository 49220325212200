import React from 'react'
import { Link } from "react-router-dom"

export default function Banner_section(props) {
  return (
      <div className={props.classnamesPass}>
          <div className="row">
              <div className="col-md-4">
                  <h1 className="banner_taital">Swami Rupeshwaranand</h1>
                  <h2 className="banner_text">Ashram</h2>
                  <div className="started_text active"><a href="https://forms.gle/x6G16ogGnpBK2NmF9" target='_blank'>Join Us</a></div>
              </div>
              <div className="col-md-5">
                  <div className="banner_img"><img src={props.image} alt={props.title} /></div>
              </div>
              <div className="col-md-3">
                  <div className="banner_menu">
                      <ul>
                          <li><Link to='/our_mission'>Our Mission</Link> <span style={{color: "#e7c100"}}>/</span></li>
                          <li><Link to='/our_servies'>Our Services</Link><span style={{color: "#e7c100"}}>/</span></li>
                          <li><Link to='/our_loaction'>Our Location</Link> <span style={{color: "#e7c100"}}>/</span></li>
                          <li><a href="https://swamirupeshwaranand.in/app" target='_blank'>Our Mobile App <span style={{color: "#e7c100"}}>/</span></a></li>
                          <li><Link to='/bank_details'>Bank details</Link>  <span style={{color: "#e7c100"}}>/</span></li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  )
}
