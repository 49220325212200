import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import HeaderSecNavbar from './HeaderSecNavbar';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { yagya_service_data } from '../WebData';
import '../css/style.css';
import '../css/responsive.css';
import '../css/bootstrap.min.css';
import '../css/jquery.mCustomScrollbar.min.css';
import { Helmet } from 'react-helmet';

// Create the function
export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement("script");
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
}

export default function YagyaDetails(props) {

    React.useEffect(() => {
            window.scrollTo(0, 0);
          }, []);
          
    const locations = useLocation();
    //const { id } = locations.state;

    const { id } = useParams();

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);


    let yagyaSdata = yagya_service_data.filter(item => item.id == id);


    return (
        <>

            <div className="header_section header_bg">

                <HeaderSecNavbar image={applogo} bookingno={booking_no} />

            </div>
            {/* header section end */}

            {yagyaSdata.map((item) => (

                <div className="container rounded bg-white ">
                    <div className="row d-flex justify-content-center pb-5">
                        <div className="col-sm-5 col-md-4 ml-1">
                            <br /><br />
                            <h4> {item.title} </h4>
                            <div className="d-flex pt-2">
                                <div><img src={item.cover} alt={item.title} /></div>
                            </div>
                            <br />
                            <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                            <hr />
                        </div>
                        <div className="col-sm-3 col-md-6 offset-md-1 mobile">
                            <div className="py-4 d-flex justify-content-end">
                                <h6> </h6>
                            </div>
                            <div className="bg-light rounded d-flex flex-column">
                                <div className="p-2 ml-3"><h4>{item.tableHeading} </h4></div>


                                <Table striped border="true" hover responsive >

                                    <thead>
                                        <tr>
                                            <th>Dakshina</th>
                                            <th>Yagna details</th>
                                            <th>Book</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{numberFormat(item.price1)}</td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: item.cat1 }}></div> </td>
                                            <td>
                                                <div className="yagya_book_btn" style={(item.btn_text1 === "" ? { display: "none" } : { display: "" })}>
                                                    <Link to='/bookingConfirm' state={{ id: item.id, image: item.cover, title: item.title, price: item.price1, text: (item.desc + "<br/><br/>" + item.cat1) }} >
                                                        {item.btn_text1} </Link></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{numberFormat(item.price2)}</td>
                                            <td><div dangerouslySetInnerHTML={{ __html: item.cat2 }}></div></td>
                                            <td>
                                                <div className="yagya_book_btn" style={(item.btn_text2 === "" ? { display: "none" } : { display: "" })}>
                                                    <Link to='/bookingConfirm' state={{ id: item.id, image: item.cover, title: item.title, price: item.price2, text: (item.desc + "<br/><br/>" + item.cat2) }} >
                                                        {item.btn_text2} </Link></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{numberFormat(item.price3)}</td>
                                            <td><div dangerouslySetInnerHTML={{ __html: item.cat3 }}></div></td>
                                            <td>
                                                <div className="yagya_book_btn" style={(item.btn_text3 === "" ? { display: "none" } : { display: "" })}>
                                                    <Link to='/bookingConfirm' state={{ id: item.id, image: item.cover, title: item.title, price: item.price3, text: (item.desc + "<br/><br/>" + item.cat3) }} >
                                                        {item.btn_text3} </Link></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{numberFormat(item.price4)}</td>
                                            <td><div dangerouslySetInnerHTML={{ __html: item.cat4 }}></div></td>
                                            <td>
                                                <div className="yagya_book_btn" style={(item.btn_text4 === "" ? { display: "none" } : { display: "" })}>
                                                    <Link to='/bookingConfirm' state={{ id: item.id, image: item.cover, title: item.title, price: item.price4, text: (item.desc + "<br/><br/>" + item.cat4) }} >
                                                        {item.btn_text4} </Link></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{numberFormat(item.price5)}</td>
                                            <td><div dangerouslySetInnerHTML={{ __html: item.cat5 }}></div></td>
                                            <td>
                                                <div className="yagya_book_btn" style={(item.btn_text5 === "" ? { display: "none" } : { display: "" })}>
                                                    <Link to='/bookingConfirm' state={{ id: item.id, image: item.cover, title: item.title, price: item.price5, text: (item.desc + "<br/><br/>" + item.cat5) }} >
                                                        {item.btn_text5} </Link></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{numberFormat(item.price6)}</td>
                                            <td><div dangerouslySetInnerHTML={{ __html: item.cat6 }}></div></td>
                                            <td>
                                                <div className="yagya_book_btn" style={(item.btn_text6 === "" ? { display: "none" } : { display: "" })}>
                                                    <Link to='/bookingConfirm' state={{ id: item.id, image: item.cover, title: item.title, price: item.price6, text: (item.desc + "<br/><br/>" + item.cat6) }} >
                                                        {item.btn_text6} </Link></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{numberFormat(item.price7)}</td>
                                            <td><div dangerouslySetInnerHTML={{ __html: item.cat7 }}></div></td>
                                            <td>
                                                <div className="yagya_book_btn" style={(item.btn_text7 === "" ? { display: "none" } : { display: "" })}>
                                                    <Link to='/bookingConfirm' state={{ id: item.id, image: item.cover, title: item.title, price: item.price7, text: (item.desc + "<br/><br/>" + item.cat7) }} >
                                                        {item.btn_text7} </Link></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>


                                <hr />
                                <div className="pt-2">
                                    <div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            ))}

            <Helmet> <script src="../js/jquery.min.js" type="text/javascript"></script>
                <script src="../js/popper.min.js" type="text/javascript"></script>
                <script src="../js/bootstrap.bundle.min.js" type="text/javascript"></script>
                <script src="../js/jquery-3.0.0.min.js" type="text/javascript"></script>
                <script src="../js/plugin.js" type="text/javascript"></script>
                <script src="../js/custom.js" type="text/javascript"></script>
                <script src="../js/jquery.mCustomScrollbar.concat.min.js" type="text/javascript"></script>
            </Helmet>

            {AddLibrary("../js/popper.min.js")}
        </>
    )
}
