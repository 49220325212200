import React from 'react'
import { Link } from "react-router-dom"
import { ashram_add } from '../AshramData'

export default function Footer_section(props) {
  return (
    <div className="footer_section layout_padding_footer">
         <div className="container">
            <div className="footer_section_2">
               <div className="row">
                  <div className="col-lg-3 col-sm-6">
                     <h2 className="useful_text">About</h2>
                     <p className="dummy_text">{ashram_add}</p>
                     <div className="location_text"><a href={"tel:"+props.number}>{props.number}</a></div>
                     <div className="location_text"><a href={"mailto:"+props.email}>{props.email}</a></div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                     <h2 className="useful_text">Useful Link</h2>
                     <div className="footer_menu">
                        <ul>
                           <li><Link to='/faq'>Frequently Asked Questions</Link></li>
                           <li><Link to='/bank_details'>Bank details</Link></li>
                           <li><Link to='/services'>Pitru Shraddh Paksha</Link></li>
                           <li><Link to='/helptoreach'>Help to Reach Ashram</Link></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                     <h2 className="useful_text">Other link</h2>
                     <div className="footer_menu">
                        <ul>
                           <li><Link to='/privacy_policy'>Privacy Policy</Link></li>
                           <li><Link to='/terms_conditions'>Terms and Conditions</Link></li>
                           <li><Link to='/refund_policy'>Refund policy</Link></li>
                           <li><Link to='/manual_payment'>Payment</Link></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                     <h2 className="useful_text">To Get Update Signup</h2>
                     <div className="form-group">
                        <textarea className="update_mail" placeholder="Enter Your WhatsApp No" rows="5" id="comment" name="whatsappNo"></textarea>
                        <div className="subscribe_bt"><a href="/">Subscribe</a></div>
                     </div>
                     <div className="social_icon">
                        <ul>
                           <li><a href="https://www.facebook.com/SwamiRupeshwarananda" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                           <li><a href="https://twitter.com/SRupeshwaranand" target='_blank'><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                           <li><a href="https://www.youtube.com/channel/UCguXru7RXLPGdLwqApPLqOg" target='_blank'><i className="fa fa-youtube" aria-hidden="true"></i></a></li>
                           <li><a href="https://www.instagram.com/swami_rupeshwaranand_official" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
  )
}
