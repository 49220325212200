import React from 'react'
//import { Link } from "react-router-dom"
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function Header_sec_navbar(props) {

   const location = useLocation(); // once ready it returns the 'window.location' object
   const [url, setUrl] = useState(null);
   useEffect(() => {
      setUrl(location.pathname);
    }, [location]);

    const nav_item_style = {
      //width:"540px"
    };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="navbar-brand"><Link to='/'><img src={props.image} alt='Swami Rupeshwaranand Ashram' /></Link></div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
               <ul className="navbar-nav ml-auto">
                  <li className={"nav-item" + (url === "/" ?" active" : "")}>
                     <Link className="nav-link" to='/'>Home</Link>
                  </li>
                  <li className={"nav-item" + (url === "/about" ?" active" : "")}>
                     <Link className="nav-link" to='/about'>About us</Link>
                  </li>
                  <li className={"nav-item" + (url === "/services" ?" active" : "")}>
                     <Link className="nav-link" to='/services'>Services</Link>
                  </li>
                  <li className={"nav-item" + (url === "/yantra" ?" active" : "")}>
                     <Link className="nav-link" to='/yantra'>Yantra/Kavach</Link>
                  </li>
                  <li className={"nav-item" + (url === "/contact" ?" active" : "")}>
                     <Link className="nav-link" to='/contact'>Contact us</Link>
                  </li>
               </ul>
               <form className="form-inline my-2 my-lg-0">
                  <div className="login_bt">
                     <ul>
                        <li>Bookings Open</li>
                        <li><a href={"tel:"+props.bookingno}>+91 {props.bookingno}</a></li>
                     </ul>
                  </div>
               </form>
            </div>
         </nav>
  )
}
