import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import Header_sec_navbar from './HeaderSecNavbar';
import Location_map from './Location_map';
import Table from 'react-bootstrap/Table';
import connectWithWhatsapp from '../images/connect_with_whatsapp.jpg'

export default function Contact_us() {

    const contact_box_style = {
        color:"#000000", 
        fontSize: "18px", 
        fontWeight: "600",
        textAlign:"center", 
        backgroundColor:"#e8c51d",
      };

      const whtsappIcon = {
        width:"250px",
      }

  return (
      <>
          <div className="header_section header_bg">

              <Header_sec_navbar image={applogo} bookingno={booking_no} />

          </div>
          {/* header section end */}

          <div className="heading_text"> Swami Rupeshwaranand Ashram </div>

          <div className="blog_section">
              <div className="container">

                  <div className="card card-body mt-5">
                      <h4 className="card-title">Get Connected</h4>
                      <h6 className="card-text" style={{ color: "#d35400" }}>We are always ready to help!!!</h6>
                      <p style={{ color: "#000000" }}>In case you wish to write to us, feel free to do so also if needed attachments can be attached and sent along with your query. <br />

                          Contact Number : +91-9817777108 / 7607233230<br />

                          Mail Id : <a href="mailto:swamirupeshwar@gmail.com" style={{ color: "#d35400" }}>swamirupeshwar@gmail.com</a></p>
                  </div>

                  <div className="card mt-1" style={contact_box_style}>
                      <div className="card-header">Contact Us</div>

                      <div className="card-body" >
                          <h5 className="card-title" style={contact_box_style}>+91-9817777108 / 7607 233 230</h5>

                          <h6 className="card-title" style={contact_box_style}><span style={{ color: "#d35400" }}>Email - swamirupeshwar@gmail.com</span></h6>

                          <p>We love our customers, so feel free to visit during normal business hours.</p>

                          <p><button className="btn btn-success" onClick={()=>{window.location='https://wa.me/919817777108'}}
                              type="button"><svg className="bi bi-whatsapp" fill="currentColor" height="16" viewBox="0 0 16 16"
                                  width="16" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232">
                                  </path>
                              </svg> Contact us on WhatsApp <br/> <img src={connectWithWhatsapp} style={whtsappIcon} alt='Whatsapp'/> </button></p>

                          <span style={contact_box_style}>Swami Rupeshwaranand Ashram</span> <p>Balua, Uttar Pradesh, India</p>

                          <span style={contact_box_style}>Hours</span>

                          <Table striped border="true" hover responsive >

                              <tbody>
                                  <tr>
                                      <td>Mon</td>
                                      <td>08:00 am</td>
                                      <td>11:00 pm</td>
                                  </tr>
                                  <tr>
                                      <td>Tue</td>
                                      <td>08:00 am</td>
                                      <td>11:00 pm</td>
                                  </tr>
                                  <tr>
                                      <td>Wed</td>
                                      <td>08:00 am</td>
                                      <td>11:00 pm</td>
                                  </tr>
                                  <tr>
                                      <td>Thu</td>
                                      <td>08:00 am</td>
                                      <td>11:00 pm</td>
                                  </tr>
                                  <tr>
                                      <td>Fri</td>
                                      <td>08:00 am</td>
                                      <td>11:00 pm</td>
                                  </tr>
                                  <tr>
                                      <td>Sat</td>
                                      <td>08:00 am</td>
                                      <td>11:00 pm</td>
                                  </tr>
                                  <tr>
                                      <td>Sun</td>
                                      <td>08:00 am</td>
                                      <td>11:00 pm</td>
                                  </tr>
                              </tbody>
                          </Table>
                      </div>
                  </div>
              </div>
          </div>


          <p><br /> &nbsp; <br /></p>
          <center><Location_map /></center>
          <br /><br />

      </>
  )
}
