import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import HeaderSecNavbar from './HeaderSecNavbar';
import ListGroup from 'react-bootstrap/ListGroup';

export default function HelpToReachAshram() {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <div className="header_section header_bg">

                <HeaderSecNavbar image={applogo} bookingno={booking_no} />

            </div>
            {/* header section end */}

            <div className="heading_text"> Swami Rupeshwaranand Ashram </div>


            <div className="accordion Container m-5" id="accordionExample">
                <div className="card">
                    <div className="card-header" data-toggle="collapse" data-target="#collapseOne" id="headingOne" aria-expanded="true" aria-controls="collapseOne">
                        <h5 className="ml-3 mt-2 mb-2 primary">
                            Assistance in reaching by public transport. 🚉
                        </h5>
                    </div>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">

                            <ListGroup as="ol" numbered>
                                <ListGroup.Item as="li"> 1. Varanasi city is connected to all major cities of the country by rail, air and bus routes.</ListGroup.Item>
                                <ListGroup.Item as="li">2. In this way, you can reach Varanasi city by rail, air and bus.</ListGroup.Item>
                                <ListGroup.Item as="li">3. After reaching Varanasi, you can take auto, bus or taxi service for Balua Ghat.</ListGroup.Item>
                                <ListGroup.Item as="li">4. Auto, bus and taxi services are easily available from Varanasi Junction to Balua Ghat.</ListGroup.Item>
                                <ListGroup.Item as="li">5. In this way, after reaching Balua Ghat, first of all, take a bath in the Ganges.</ListGroup.Item>
                                <ListGroup.Item as="li">6. After that you can reach the ashram by walking for about 5 minutes.</ListGroup.Item>
                            </ListGroup>

                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" data-toggle="collapse" data-target="#collapseTwo" id="headingOne" aria-expanded="true" aria-controls="collapseOne">
                        <h5 className="ml-3 mt-2 mb-2 primary">
                            Assistance to reach by private vehicle. 🚘
                        </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            If you are coming by your personal vehicle then you can take the help of Google Map, the link of which is available on the contact page.
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
