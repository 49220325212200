import React from 'react'
import { Link } from 'react-router-dom';

export default function Post_section(props) {
    const numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR'
        }).format(value);
        
  return (
      <div className="col-md-4">
          <div className="blog_box">
              <div className="blog_img"><img src={props.image}/></div>
              <div className="blog_taital_main">
                  <p className="yoga_text">{numberFormat(props.subtitle)}</p>
                  <h3 className="practising_taital">{props.title}</h3>
                  <div className="blog_text"><div dangerouslySetInnerHTML={{__html: props.text}}></div></div>
                  <div className="date_btn" style={(props.btn_text === "" ? {display:"none"} : {display:""})}> <Link to='/bookingConfirm' state={{id: 1, image: props.image, title: props.title, price: props.subtitle, text: props.text}} > {props.btn_text} </Link></div>
              </div>
          </div>
      </div>
  )
}
