import React from 'react'
import { BrowserRouter as Router, Navigate, Routes, Route } from "react-router-dom"
import Footer_section from './componants/Footer_section';
import Credit_section from './componants/Credit_section';
import { booking_no } from './AshramData';
import { booking_email } from './AshramData';
import Home from './componants/Home';
import About_us from './componants/About_us';
import Services from './componants/Services';
import Yantra from './componants/Yantra';
import Contact_us from './componants/Contact_us';
import My404error from './componants/My404error';
import Privacy_policy from './componants/Privacy_policy';
import Refund_policy from './componants/Refund_policy';
import Terms_conditions from './componants/Terms_conditions';
import { misson_banner_data, about_section_data, service_banner_data, location_banner_data, bank_banner_data } from './WebData';
import Faq from './componants/Faq';
import Payment_Page from './componants/Payment_Page';
import Manual_Payment from './componants/Manual_Payment';
import YagyaDetails from './componants/YagyaDetails';
import HelpToReachAshram from './componants/HelpToReachAshram';

function App() {

  const service_title="";

  return (
    <>
  
      <Router>
          <Routes>
            <Route exact path='/' element={<Home service_title={service_title} />} />
            <Route exact path='/about' element={about_section_data.map((item) =>( <About_us key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc} btn_text={item.btn_text} />))} />
            <Route exact path='/services' element={<Services service_title={service_title} />} />
            <Route exact path='/yantra' element={<Yantra service_title={service_title} />} />
            <Route exact path='/contact' element={<Contact_us/>} />
            <Route exact path='/privacy_policy' element={<Privacy_policy/>} />
            <Route exact path='/refund_policy' element={<Refund_policy/>} />
            <Route exact path='/terms_conditions' element={<Terms_conditions/>} />
            <Route exact path='*' element={<Navigate to="/404" replace />}/>
            <Route exact path='/404' element={<My404error/>} />

            <Route exact path='/our_mission' element={misson_banner_data.map((item)=>(<About_us key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc}  btn_text={item.btn_text} />))} />
            <Route exact path='/our_servies' element={service_banner_data.map((item)=>(<About_us key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc}  btn_text={item.btn_text} />))} />
            <Route exact path='/our_loaction' element={location_banner_data.map((item)=>(<About_us key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc}  btn_text={item.btn_text} />))} />
            <Route exact path='/bank_details' element={bank_banner_data.map((item)=>(<About_us key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc}  btn_text={item.btn_text} />))} />
            <Route exact path='/faq' element={<Faq/>} />
            <Route exact path='/bookingConfirm' element={<Payment_Page />} />
            <Route exact path='/yagyaDetails/:id' element={<YagyaDetails />} />
            <Route exact path='/manual_payment' element={<Manual_Payment />} />
            <Route exact path='/helptoreach' element={<HelpToReachAshram/>} />
            

            {/*<Route exact path='/helptoreach' element={<Faq/>} />*/}
          </Routes>


        {/* Footer section start */}
        <Footer_section number={booking_no} email={booking_email} />
        {/* Footer section end */}

        {/* Credit section start */}
        <Credit_section />
        {/* Credit section end */}

      </Router>
    </>
  );
}

export default App;
