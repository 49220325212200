import React from 'react'
import phonepe_icon from '../images/phonepelogo.png'
import { Link } from 'react-router-dom';
import $ from "jquery";
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';

export default function Price_confirmation(props) {

  React.useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
        
  //const [loading2, setLoading2] = useState(false);
  const [names, setNames] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [btn_name, setBtnName] = useState("Proceed to payment");
  const [isDisabled, setIsDisabled] = useState(false);

  let [quantity, setQuantity] = useState(1);


  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);

  var price = props.price;
  var anyOtherCharges = 0.00;
  var gst = 0.00;
  var subTotal = parseInt(price) + parseInt(anyOtherCharges) + parseInt(gst);
  let totals = subTotal * quantity;

  const mydata = {
    name: names,
    email: email,
    amount: totals,
    mobile: mobile,
    service: props.title,
  }

  function handleMinus() {
    if(quantity > 1){
      setQuantity(quantity-1);
    }
  }

  function handlePlus(){
    if (quantity < 11) {
      //quantity = quantity + 1;
      setQuantity(quantity+1);
    }
  }

  const handlePayment = async (e) => {
    e.preventDefault();

    if(names === ""){
      alert("Enter Your Name.");
      return;
    } else if(email === ""){
      alert("Enter Your Email.");
      return;
    } else if(mobile === ""){
      alert("Emter Your Mobile No.");
      return;
    }

    //setLoading2(true);
    //setNames(e.target.value);
    //setEmail(e.target.value);
    //setMobile(e.target.value);

    setBtnName("Please wait...");
    setIsDisabled(true);
    

   /* let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };*/

    $.ajax({
      method: "POST",
      //url: "http://localhost:8000/phonepe.php",
      url: "/payment/pay.php",
      data: mydata,
    })
      .done(function (msg_url) {
        setBtnName("Proceed to payment");
        setIsDisabled(false);
        //alert( msg_url );
        window.location.href = msg_url;
      });

    /*await axios.post('http://localhost:8000/payment', data, axiosConfig).then((res) => {  
      console.log(res.data);
    })
    .catch(error => {
        //setLoading2(false)
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
    });   */
  }

  return (
    <>

      <div className="container rounded bg-white">
        <div className="row d-flex justify-content-center pb-5">
          <div className="col-sm-5 col-md-5 ml-1">
            <div className="py-4 d-flex flex-row">
              <h5><span><img src={phonepe_icon} width={30} alt='PhonePe' /></span><b>PhonePe</b> | </h5><span className="pl-2">Pay</span>
            </div>
            <h4 className="green"> {numberFormat(price)} </h4>
            <h4> {props.title} </h4>
            <div className="d-flex pt-2">
              <div><img src={props.image} alt={props.title} /></div>
            </div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
            <hr />
          </div>
          <div className="col-sm-3 col-md-4 offset-md-1 mobile">
            <div className="py-4 d-flex justify-content-end">
              <h6><Link to='/' replace>Cancel and return to website</Link></h6>
            </div>
            <div className="bg-light rounded d-flex flex-column">
              <div className="p-2 ml-3"><h4>Order Recap</h4></div>
              <div className="p-2 d-flex">
                <div className="col-6">Amount</div>

                <Row><div className="def-number-input number-input">
                            <button className="minus" onClick={handleMinus} ></button>
                            <input
                              className="quantity fw-bold text-black"
                              min={0}
                              type="number"
                              value={quantity}
                              onChange={e => setQuantity(e.target.value)}
                              readOnly
                            />
                            <button className="plus" onClick={handlePlus}></button>
                          </div></Row>

                <div className="ml-auto"> {numberFormat(price)} </div>
              </div>
              <div className="p-2 d-flex">
                <div className="col-8">Any Other Charges</div>
                <div className="ml-auto">{numberFormat(anyOtherCharges)}</div>
              </div>

              <div className="border-top px-4 mx-3">
              </div>
              <div className="p-2 d-flex pt-3">
                <div className="col-8">GST</div>
                <div className="ml-auto">{numberFormat(gst)}</div>
              </div>

              {/*<div className="border-top px-4 mx-3"></div>
                <div className="p-2 d-flex pt-3">
                    <div className="col-8">Responsibility</div>
                    <div className="ml-auto"><b>₹71.76</b></div>
                </div>
                <div className="p-2 d-flex">
                    <div className="col-8">Other Balance  <span className="fa fa-question-circle text-secondary"></span></div>
                    <div className="ml-auto"><b>₹71.76</b></div>
                </div>*/}
              <div className="border-top px-4 mx-3"></div>
              <div className="p-2 d-flex pt-3">
                <div className="col-8"><b>Total</b></div>
                <div className="ml-auto"><b className="green"> {numberFormat(totals)} </b></div>
              </div>

              <Form.Control type="text" placeholder="Enter Your Name" onChange={e => setNames(e.target.value)} /> <br/>
              <Form.Control type="email" placeholder="Enter Your E-mail" onChange={e => setEmail(e.target.value)} /> <br/>
              <Form.Control type="tel" placeholder="Enter Your Mobile No." onChange={e => setMobile(e.target.value)} />

              <hr />
              <div className="pt-2">
                <div>
                  <input type="button" disabled={isDisabled} onClick={handlePayment} value={btn_name} className="btn btn-primary btn-block" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}
