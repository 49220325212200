import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import Header_sec_navbar from './HeaderSecNavbar';
import Price_confirmation from './Price_confirmation';
import { useLocation } from 'react-router-dom';

export default function Payment_Page() {

  React.useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
        
    const location  = useLocation();
    const { id, image, title, price, text } = location.state;
  return (
    <>
      
      <div className="header_section header_bg">

              <Header_sec_navbar image={applogo} bookingno={booking_no} />

          </div>
          {/* header section end */}

          <Price_confirmation image={image} title={title} price={price} text={text} />
    </>
  )
}
