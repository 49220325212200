import React from 'react'
import { useCallback, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function About_section(props) {

   const location = useLocation(); // once ready it returns the 'window.location' object
   const [url, setUrl] = useState(null);
   useEffect(() => {
      setUrl(location.pathname);
    }, [location]);


   const videoRef = useRef(null);

   const handleMouseEnter = useCallback(() => {
      videoRef.current.play();
   }, []);

   const handleMouseLeave = useCallback(() => {
      videoRef.current.pause();
   }, []);

   return (
      <>

         <div className="about_section ">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="heading_text"> {props.title} </div>
                  </div>
               </div>
            </div>
            <div className="about_section_2">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6">
                        <div className="about_img">
                           <img src={props.image} alt={props.title} style={{display:(url === "/about" ?" none" : "")}} />
                        
                           <video style={{display:(url === "/about" ?"" : " none")}}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              ref={videoRef}
                              src='Video/about_ashram.mp4'
                              type="video/mp4"
                              className="w-100">
                              </video>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="about_taital_main">
                           <div className="yoga_taital">{props.subtitle} </div>
                           <div className="about_text"> <div dangerouslySetInnerHTML={{ __html: props.text }}></div> </div>
                           <div className="read_bt" style={(props.btn_text === "" ? { display: "none" } : { display: "" })}>{props.btn_text}</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </>
   )
}
