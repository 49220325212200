import React from 'react'
import Header_sec_navbar from './HeaderSecNavbar';
import applogo from '../images/logo.png'
import Banner_section from './Banner_section';
import banner_bg from '../images/banner_bg.png';
import banner_img from '../images/banner_img.png';
import banner_img_2 from '../images/banner_img_2.png';
import banner_img_3 from '../images/banner_img_3.png';
import About_section from './About_section';
import Post_section from './Post_section';
import { booking_no } from '../AshramData';
import { guidence_service_data, about_section_data, yagya_service_data } from '../WebData';
import YagyaSection from './YagyaSection';


export default function Home(props) {
    return (
        <>
            <div className="header_section" style={{ backgroundImage: `url(${banner_bg})` }}>

                <Header_sec_navbar image={applogo} bookingno={booking_no} />

                {/* banner section start */}
                <div className="banner_section layout_padding">
                    <div className="container-fluid">
                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            </ol>
                            <div className="carousel-inner">
                                <Banner_section image={banner_img} classnamesPass="carousel-item active" />
                                <Banner_section image={banner_img_2} classnamesPass="carousel-item" />
                                <Banner_section image={banner_img_3} classnamesPass="carousel-item" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* banner section end */}
            </div>
            {/* header section end */}

            {/* About section start */}

            {/* {about_section_data.map((item) => (
              <About_section key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc} btn_text={item.btn_text} />
          ))} */}

            {/* About section end */}

            <div className="heading_text"> Swami Rupeshwaranand Ashram </div>

            {/* Yagya Section */}
            <div className="blog_section ">
                <div className="container">
                    <div className="blog_section_2">
                        <div className="row">
                            {yagya_service_data.map((item) => (
                                <YagyaSection id={item.id} image1={item.cover1} image2={item.cover2} image3={item.cover3} title={item.title} subtitle={item.subtitle} text={item.desc} btn_text={item.btn_text} />
                            ))}

                        </div>
                    </div>
                </div>
            </div>


            {/* Post section start */}
            <div className="blog_section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="blog_taital">{props.service_title}</h1>
                        </div>
                    </div>
                    <div className="blog_section_2">
                        <div className="row">
                            {guidence_service_data.map((item) => (
                                <Post_section key={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc} btn_text={item.btn_text} />
                            ))}

                            {/* {yagya_service_data.map((item) => (
                                <YagyaSection id={item.id} image={item.cover} title={item.title} subtitle={item.subtitle} text={item.desc} btn_text={item.btn_text} />
                            ))} */}

                        </div>
                    </div>
                </div>
            </div>
            {/* Post section end */}


        </>
    )
}
