import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import HeaderSecNavbar from './HeaderSecNavbar';
import AboutSection from './About_section';

export default function About_us(props) {

  React.useEffect(() => {
          window.scrollTo(0, 0);
        }, []);

  return (
      <>
          <div className="header_section header_bg">

              <HeaderSecNavbar image={applogo} bookingno={booking_no} />

          </div>
          {/* header section end */}

          {/* About section start */}
          
            <AboutSection image={props.image} title={props.title} subtitle={props.subtitle} text={props.text} btn_text={props.btn_text} />
          
          {/* About section end */}
      </>
  )
}
