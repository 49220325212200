import React from 'react'

export default function location_map() {
  return (
    <div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.434863480997!2d83.17867567416238!3d25.423721122490726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e21a622e20243%3A0xeb5b0107f7ea44e6!2sSwami%20Rupeshwaranand%20Ashram!5e0!3m2!1sen!2sin!4v1725274414540!5m2!1sen!2sin" width="90%" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

    </div>
  )
}
