import React from 'react'

export default function Credit_section() {
  return (
    <div className="copyright_section">
         <div className="container">
            <p className="copyright_text">Copyright &copy; 2025 Swami Rupeshwaranand Ashram - All Rights Reserved. </p>
         </div>
      </div>
  )
}
