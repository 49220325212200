import React from 'react';
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import Header_sec_navbar from './HeaderSecNavbar';
import Card from 'react-bootstrap/Card';

export default function Faq() {
    React.useEffect(() => {
            window.scrollTo(0, 0);
          }, []);
          
    return (
        <>

            <div className="header_section header_bg">

                <Header_sec_navbar image={applogo} bookingno={booking_no} />

            </div>
            {/* header section end */}

            <center>
                <div className="heading_text"> Swami Rupeshwaranand Ashram </div>

                <Card.Title className="mt-5" style={{ color: " #d35400" }}>Frequently Asked Questions</Card.Title>

                <Card.Body>Please reach us at <a href="mailto: swamirupeshwar@gmail.com">swamirupeshwar@gmail.com</a> if you cannot find an answer to your question.</Card.Body>
            </center>

            <div className="accordion Container m-5" id="accordionExample">
                <div className="card">
                    <div className="card-header" data-toggle="collapse" data-target="#collapseOne" id="headingOne" aria-expanded="true" aria-controls="collapseOne">
                        <h5 className="ml-3 mt-2 mb-2 primary">
                            Where is Pujan performed ?
                        </h5>
                    </div>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            Pujans are performed at Swami Rupeshwaranand Ashram as well as in Haridwar as per Yagyamaan's suitability.
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" data-toggle="collapse" data-target="#collapseTwo" id="headingOne" aria-expanded="true" aria-controls="collapseOne">
                        <h5 className="ml-3 mt-2 mb-2 primary">
                            Mode of Pujan?
                        </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            Online as well as offline Pujan facility is provided by Swami Rupeshwaranand Ashram.
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" data-toggle="collapse" data-target="#collapseThree" id="headingOne" aria-expanded="true" aria-controls="collapseOne">
                        <h5 className="ml-3 mt-2 mb-2 primary">
                            Mode of Payment?
                        </h5>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            Online payment as well as Pay at the time of Pujan in cash is accepted.
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" data-toggle="collapse" data-target="#collapseFour" id="headingOne" aria-expanded="true" aria-controls="collapseOne">
                        <h5 className="ml-3 mt-2 mb-2 primary">
                            In Online Pujan, how we will get confirmation that Pujan is done?
                        </h5>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            Sankalp under the name of Yagyamaan, photos, videos of Pujan are all sent on the respective WhatsApp number of registered person.
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" data-toggle="collapse" data-target="#collapseFive" id="headingOne" aria-expanded="true" aria-controls="collapseOne">
                        <h5 className="ml-3 mt-2 mb-2 primary">
                            Any number or mail we can contact on in case needed?
                        </h5>
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            Yes, You can contact on +91-9817777108, +91-7607233230 or mail us at <a href="mailto:swamirupeshwar@gmail.com">swamirupeshwar@gmail.com</a>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header" data-toggle="collapse" data-target="#collapseSix" id="headingOne" aria-expanded="true" aria-controls="collapseOne">
                        <h5 className="ml-3 mt-2 mb-2 primary">
                            Any other contact numbers?
                        </h5>
                    </div>
                    <div id="collapseSix" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            Yes, +91-9565229993 / +91-9565339993 / +91-9565449993
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
