import React from 'react'
import applogo from '../images/logo.png';
import { booking_no } from '../AshramData';
import Header_sec_navbar from './HeaderSecNavbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import $ from "jquery";
import { useState } from 'react';

export default function Manual_Payment() {

  React.useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
        
    const [names, setNames] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [service, setService] = useState("");
    const [amount, setAmount] = useState("");

    const mydata = {
        name: names,
        email: email,
        amount: parseInt(amount),
        mobile: mobile,
        service: service,
      }

      const handlePayment = async (e) => {
        e.preventDefault();
    
        if(names === ""){
            alert("Enter Your Name.");
            return;
          } else if(email === ""){
            alert("Enter Your Email.");
            return;
          } else if(mobile === ""){
            alert("Emter Your Mobile No.");
            return;
          } else if(amount === ""){
            alert("Emter Amount.");
            return;
          }

        $.ajax({
          method: "POST",
          //url: "http://localhost:8000/phonepe.php",
          url: "/payment/pay.php",
          data: mydata,
        })
          .done(function (msg_url) {
            //alert( msg_url );
            window.location.href = msg_url;
          });
    
      }

    return (
        <>

            <div className="header_section header_bg">

                <Header_sec_navbar image={applogo} bookingno={booking_no} />

            </div>
            {/* header section end */}

            <div className="heading_text"> Swami Rupeshwaranand Ashram </div>

            <center>
                <div className='m-5' style={{ width: "70%" }}>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Control type="text" placeholder="Enter Your Name" onChange={e => setNames(e.target.value)} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Enter Your E-mail" onChange={e => setEmail(e.target.value)} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicTel">
                            <Form.Control type="tel" placeholder="Enter Your Mobile No." onChange={e => setMobile(e.target.value)} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicService">
                            <Form.Control type="text" placeholder="Enter Service Name" onChange={e => setService(e.target.value)} required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicAmount">
                            <Form.Control type="number" placeholder="Enter Amount" onChange={e => setAmount(e.target.value)}required />
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={handlePayment} >
                            Proceed to Payment
                        </Button>
                    </Form>
                </div>
            </center>
        </>
    )
}
